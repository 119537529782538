import React from "react";
import ReactGA from "react-ga";
const Faq = () => {

  const BitterShot=()=>{

    ReactGA.event({
      category:'dropdown',
      action:'View  How does BITTERSHOT work'
    })
  }
  const BitterShortEffect=()=>{

    ReactGA.event({
      category:'dropdown',
      action:'View  How long does the BITTERSHOT effect last'
    })
  }
  const BitterShortLethal=()=>{

    ReactGA.event({
      category:'dropdown',
      action:'View  How long does the BITTERSHOT effect last'
    })
  }
  const BitterShortExpire=()=>{

    ReactGA.event({
      category:'dropdown',
      action:'View  How long does the BITTERSHOT effect last'
    })
  }
  const BitterShortDiff=()=>{

    ReactGA.event({
      category:'dropdown',
      action:'View  How long does the BITTERSHOT effect last'
    })
  }
  const BitterShortRemove=()=>{

    ReactGA.event({
      category:'dropdown',
      action:'View  How long does the BITTERSHOT effect last'
    })
  }
  const BitterShortStore=()=>{

    ReactGA.event({
      category:'dropdown',
      action:'View  How long does the BITTERSHOT effect last'
    })
  }

  return (
    <React.Fragment>
      <p className="faq-page-head">FREQUENTLY ASKED QUESTIONS</p>
      <div className="faq-div">
        <div class="accordion" id="accordionPanelsStayOpenExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne"
                onClick={BitterShot}
              >
                How does BITTERSHOT work?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="panelsStayOpen-headingOne"
            >
              <div class="accordion-body">
                BITTERSHOT contains an extreme bitter chemical that is
                intolerable to humans and animals on contact. When BITTERSHOT is
                sprayed on the face of an attacker, it triggers an instant
                bitter sensation in the mouth and through the nasal cavity that
                deters the assailant. The reaction ranges from eye watering,
                temporary blindness, breathing difficulty and intense unpleasant
                taste. The powerful reaction temporarily overcomes the sprayed
                individual, diverts the attack and allows the sprayer a few
                vital minutes to escape or call for help.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseTwo"
                onClick={BitterShortEffect}

              >
                How long does the BITTERSHOT effect last?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingTwo"
            >
              <div class="accordion-body">
                The BITTERSHOT formulation causes an acute and intense sensation
                that severely restrains the attacker. The high-intense period is
                for one or two minutes which allows the victim to disengage and
                escape or call for help. The temporary discomfort wears off
                gradually in one ortwo hours.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseThree"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseThree"
                onClick={BitterShortLethal}
              >
                Is BITTERSHOT lethal?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingThree"
            >
              <div class="accordion-body">
                Under test conditions BITTERSHOT has not been proven to be life
                threatening. It has not been known to cause any permanent or
                long-term physical damage to the recipient.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingFour">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseFour"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseFour"
                onClick={BitterShortExpire}
              >
                When does a BITTERSHOT spray expire?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingFour"
            >
              <div class="accordion-body">
                The BITTERSHOT spray has a long storage life of 3 years from the
                date of packing
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingFive">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseFive"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseFive"
                onClick={BitterShortDiff}
              >
                How is BITTERSHOT different from 'Pepper Spray'?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingFive"
            >
              <div class="accordion-body">
                Pepper Spray contains Oleoresin Capsicum, which is derived from
                chili Peppers. The Pepper Spray when used strongly, can have
                long lasting effects on the person and sometimes on the user as
                well. BITTERSHOT contains an extreme bitter chemical which is
                Non-toxic and Non-poisonous due to which there are no
                long-lasting effects.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingSix">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseSix"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseSix"
                onClick={BitterShortRemove}
              >
                How to remove the direct or indirect effect of the BITTERSHOT
                spray?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseSix"
              class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingSix"
            >
              <div class="accordion-body">
                In general, repeated washing off with soap and water will dilute
                the effect. Consumption of a sweet substance will diminish the
                intense bitter taste in the mouth.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingseven">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseseven"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseseven"
                onClick={BitterShortStore}
              >
                How and where to store BITTERSHOT?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseseven"
              class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingseven"
            >
              <div class="accordion-body">
                BITTERSHOT is a pressurized container and has to be stored in a
                cool and dry place, away from heat, flame and strong light.
                Don't store at temperatures exceeding 450°C. Avoid keeping in a
                car dashboard compartment.
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Faq;
