import "./App.css";
import "./assets/css/main.css";
import Homepage from "./pages/HomePage";
import Usage from "./pages/Usage";
import Features from "./pages/Features";
import Faq from "./pages/Faq";
import Enquiry from "./pages/Enquiry";
import Buy from "./pages/Buy";

import Header from "./components/Header";
import Footer from "./components/Footer";

import ScrollToTop from "./components/ScrollToTop";

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
//import ReactGA from 'react-ga';
import ReactGA from "react-ga";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

const App = () =>  {
  let oldScrollY = 0;
  ReactGA.initialize('UA-238060414-2');
const [direction, setdirection] = useState('up');

const controlDirection = () => {
    if(window.scrollY > oldScrollY) {
        setdirection('down');
        // console.log('scrolling down');
        var element = document.getElementById('adjustnavbar');
        element.classList.add('desknewnav');
        // console.log(element.classList);
    } else {
        setdirection('up');
        // console.log('scrolling up >>' + window.scrollY);
        if(window.scrollY == 0) {
          var element = document.getElementById('adjustnavbar');
        element.classList.remove('desknewnav');
        }        
    }
    oldScrollY = window.scrollY;    
}

useEffect(() => {
 //ReactGA.send("pageview");
  ReactGA.pageview(window.location.pathname + window.location.search);

    window.addEventListener('scroll', controlDirection);
    return () => {
        window.removeEventListener('scroll', controlDirection);
    };
},[]);

return (
  <Router>
    <Header />
    <Switch>
      <Route path="/" exact>
        <ScrollToTop>
          <Homepage />
        </ScrollToTop>
      </Route>
      <Route path="/usage" exact>
        <ScrollToTop>
          <Usage /> 
        </ScrollToTop>        
      </Route>
      <Route path="/features" exact>
        <ScrollToTop>
          <Features />
        </ScrollToTop>        
      </Route>
      <Route path="/faq" exact>
        <ScrollToTop>
          <Faq />
        </ScrollToTop>        
      </Route>
      <Route path="/enquiry" exact>
        <ScrollToTop>
          <Enquiry />
        </ScrollToTop>        
      </Route>
      <Route path="/buy" exact>
        <ScrollToTop>
          <Buy />
        </ScrollToTop>        
      </Route>
    </Switch>
    <Footer />
  </Router>
);
}

export default App;
