import React from "react";

import can from "./../assets/images/can.png";
import newhomecan from './../assets/images/newhomecan.png';

const MainBanner = () => {
  return (
    <React.Fragment>
      <section>
        <div className="section1-bg">
          <div className="row">
            <div className="col-6 col-lg-6">
              <img src={newhomecan} className="section1-can" />
            </div>
            <div className="col-6 col-lg-6">
              <p className="protect-yourself-head">
                PROTECT
                <br />
                YOURSELF
              </p>
              <a href="https://www.amazon.in/BITTERSHOT-Unscented-Personal-Defence-Spray/dp/B09JVYL18J/ref=sr_1_5?crid=3HCNEVR5R0LG1&dchild=1&keywords=bittershot&qid=1635159453&qsid=257-3054091-6957760&sprefix=bittershot%2Caps%2C230&sr=8-5&sres=B09JVYL18J" className="buy-btn" target="_blank">
                buy now
              </a>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default MainBanner;
