import React from "react";
import MainBanner from "../components/MainBanner";

import Feature1 from "./../assets/images/feature1.png";
import Feature2 from "./../assets/images/feature2.png";
import Feature3 from "./../assets/images/feature3.png";
import Feature4 from "./../assets/images/feature4.png";
import Feature5 from "./../assets/images/feature5.png";
import Feature6 from "./../assets/images/feature6.png";
import can from "./../assets/images/can.png";

import featuretopleft from './../assets/images/featuretopleft.png';
import featuretopleft1 from './../assets/images/featuretopleft1.png';

const Features = () => {
  return (
    <React.Fragment>
      {/* <MainBanner /> */}
      <section>
        <div className="featurebg-main">
          <p className="feature-main-text mobhide">
              FOR THE  
                <br />
                <span className="tough">TOUGH</span> <br />
                MOMENTS <br />IN LIFE
          </p>
          <p className="feature-main-text deskhide">
              FOR THE TOUGH<br />
                MOMENTS IN LIFE
          </p>
          <img src={featuretopleft} className="featuretopleft mobhide" />
          <img src={featuretopleft1} className="featuretopleft deskhide" />
          <div className="featurehead-can-div">
              <img src={can} className="feature-head-can" alt="product" />
          </div>
          
        </div>
      </section>
      <section>
        <div className="featurebg">
          <div className="feature-row">
            <div className="row">
              <div className="col-6 col-lg-6">
                <div className="feature-width-set">
                  <img src={Feature1} className="featureimg" />
                </div>
              </div>
              <div className="col-6 col-lg-6">
                <div className="feature-width-set">
                  <div className="align-middle">
                    <p className="featurehead">REACH 6-8 FEET</p>
                    <p className="featuretext">
                      Anticipate the attacker’s movement
                      <br /> and apply at a safe distance
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-lg-6">
                <div className="feature-width-set">
                  <div className="align-middle right-align">
                    <p className="featurehead">STRIKING COLORS</p>
                    <p className="featuretext">
                      A strong visual marker which is
                      <br /> easy to spot even in the dark, and
                      <br /> among other thing
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-6">
                <div className="feature-width-set">
                  <img src={Feature2} className="featureimg" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-lg-6">
                <div className="feature-width-set">
                  <img src={Feature3} className="featureimg" />
                </div>
              </div>
              <div className="col-6 col-lg-6">
                <div className="feature-width-set">
                  <div className="align-middle">
                    <p className="featurehead">
                      ACCIDENTAL SPRAY <br />
                      PROTECTION
                    </p>
                    <p className="featuretext">
                      A finger guard that prevents
                      <br /> accidental spraying while carrying
                      <br /> or handling
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-lg-6">
                <div className="feature-width-set">
                  <div className="align-middle right-align">
                    <p className="featurehead">EASY CARRY CLIP</p>
                    <p className="featuretext">
                      Clip the can to your bag, keychain
                      <br /> or belt for quick deployment
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-6">
                <div className="feature-width-set">
                  <img src={Feature4} className="featureimg" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-lg-6">
                <div className="feature-width-set">
                  <img src={Feature5} className="featureimg" />
                </div>
              </div>
              <div className="col-6 col-lg-6">
                <div className="feature-width-set">
                  <div className="align-middle">
                    <p className="featurehead">HANDY SIZE</p>
                    <p className="featuretext">
                      Designed to fit into a small purse or
                      <br /> a pocket and gripped in a small palm
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-lg-6">
                <div className="feature-width-set">
                  <div className="align-middle right-align">
                    <p className="featurehead">POWERFUL JET</p>
                    <p className="featuretext">
                      High pressure spray. A half second burst
                      <br /> should deter an attacker
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-6">
                <div className="feature-width-set">
                  <img src={Feature6} className="featureimg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Features;
