import React from "react";

import { Link, NavLink } from "react-router-dom";

import Logo from "./../assets/images/logo.png";

const dismenu = () => {
  var element = document.getElementsByClassName("mycss");
  var navtog = document.getElementsByClassName("navbar-toggler");
  if (element.length > 0) {
    element[0].classList.remove("show");
    navtog[0].setAttribute("aria-expanded", "false");
  } else {
  }
};

const Header = () => {
  return (
    <React.Fragment>
      {/* { elements = document.getElementsByClassName("classname");} */}
      <div className="main-navbar mobhide" id="adjustnavbar">
        <NavLink exact to="/">
          <img src={Logo} className="main-logo" />
        </NavLink>
        <nav className="navbar navbar-expand-sm">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink exact className="nav-link" to="/">
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink exact className="nav-link" to="/features">
                Features
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink exact className="nav-link" to="/usage">
                Usage
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/buy">
                Buy
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/faq">
                Faq
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/enquiry">
                Enquiry
              </NavLink>
            </li>            
          </ul>
        </nav>
      </div>

      <nav class="navbar navbar-dark bg-dark navbar-expand-lg deskhide">
        <div class="container-fluid">
          <NavLink
            exact
            className="mainlogo"
            onClick={dismenu}
            id="mylogo"
            to="/"
          >
            <img src={Logo} className="main-logo" />
          </NavLink>
          <button
            class="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <input type="checkbox" />
            {/* <span className="navbar-toggler-icon"></span> */}
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <div className="collapse navbar-collapse mycss" id="navbarScroll">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink exact onClick={dismenu} className="nav-link" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  className="nav-link"
                  onClick={dismenu}
                  to="/usage"
                >
                  Usage
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  className="nav-link"
                  onClick={dismenu}
                  to="/features"
                >
                  Features
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" onClick={dismenu} to="/faq">
                  Faq
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" onClick={dismenu} to="/enquiry">
                  Enquiry
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" onClick={dismenu} to="/buy">
                  Buy
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="mobnav-height"></div>
    </React.Fragment>
  );
};

export default Header;
