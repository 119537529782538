import React from "react";

import MainBanner from "../components/MainBanner";
import midimage from "./../assets/images/mid-image.png";
import bag from "./../assets/images/bag.png";
import pant from "./../assets/images/pant.png";
import belt from "./../assets/images/belt.png";
import section2img from "./../assets/images/section2bg.png";

import ReactGA from "react-ga";
const Homepage = () => {


  const ClickHandler=()=>{
    console.log("testing")
    ReactGA.event({
      category:"link",
      action:'Click home page amazon buy button '
    })
  }
  return (
    <React.Fragment>
      <MainBanner />
      <section>
        <div className="section2bg">
          <img src={section2img} className="sec2imgmob deskhide" />
          <div className="section2-box">
            <div className="section2-content">
              <p className="box-head">AWARENESS. AVOIDANCE. ACTION</p>
              <p className="box-text">
                The three pillars of physical safety and self-defence. Use the
                BITTERSHOT spray as
                <br /> the last recourse when facing unavoidable physical danger
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="section3bg">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="section3bg-img">
                <img src={midimage} />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="section-text">
                <p>
                  BITTERSHOT is a Personal Defence Spray&nbsp;
                  <br />
                  to deter an attacker intending physical&nbsp;
                  <br />
                  harm. When cornered, aim at the attacker's&nbsp;
                  <br />
                  face and spray.
                </p>
                <p>
                  BITTERSHOT will spray a bitter blast&nbsp;
                  <br />that will temporarily impair vision and cause&nbsp;
                  <br />
                    severe irritation to the attacker and trigger&nbsp; 
                  <br />
                  instant distraction. This may buy you&nbsp;
                  <br />
                  precious time to escape and/or call for help.&nbsp;
                </p>
                <a onClick={ClickHandler} href="https://www.amazon.in/BITTERSHOT-Unscented-Personal-Defence-Spray/dp/B09JVYL18J/ref=sr_1_5?crid=3HCNEVR5R0LG1&dchild=1&keywords=bittershot&qid=1635159453&qsid=257-3054091-6957760&sprefix=bittershot%2Caps%2C230&sr=8-5&sres=B09JVYL18J" className="buy-btn" target="_blank">
                  buy now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="compare-bg">
          <div className="compare-text-box">
            <p className="uptext">
              Pepper Spray contains Oleoresin Capsicum, which is
              <br /> derived from chili Peppers. The Pepper Spray when used
              <br />
              &nbsp;strongly, can have long lasting effects on the person and
              <br />
              sometimes on the user as well.
            </p>
            <p className="downtext">
              BITTERSHOT contains an extreme bitter chemical
              <br />
              which is Non-toxic & Non-poisonous due to which
              <br />
              there are no long-lasting effects.
            </p>
          </div>
        </div>
      </section>

      <section className="greysection">
        <div className="greybg">
          <div className="grey-row">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4">
                <img src={bag} className="bag-img"></img>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <img src={pant} className="bag-img"></img>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <img src={belt} className="bag-img"></img>
              </div>
            </div>
          </div>
          <div className="grey-textarea">
            <div className="row">
              <div className="col-12 col-lg-3">
                <p className="grey-head">
                  MAKE, BUILT
                  <br /> & SAFETY
                </p>
              </div>
              <div className="col-12 col-lg-9">
                <p className="grey-text">
                  The BITTERSHOT cannister has been sized to fit inside a lady's
                  purse, a trouser pocket or a bag.
                  <br /> It can be carried clipped to the belt. The vibrant canister
                  colour can be seen and identified in
                  <br />
                  the dark.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Homepage;
