import React from "react";

import midimage from "./../assets/images/mid-image.png";
import amazon from "./../assets/images/amazon.png";
import flipkart from "./../assets/images/flipkart.png";
import snapdeal from "./../assets/images/snapdeal.png";
import shopclus from "./../assets/images/shopclus.png";
import paytm from "./../assets/images/paytm.png";

import Feature1 from "./../assets/images/feature1.png";
import Feature2 from "./../assets/images/feature2.png";
import Feature3 from "./../assets/images/feature3.png";
import Feature4 from "./../assets/images/feature4.png";
import Feature5 from "./../assets/images/feature5.png";
import Feature6 from "./../assets/images/feature6.png";

import icon01 from "./../assets/images/icon01.png";
import icon02 from "./../assets/images/icon02.png";
import icon03 from "./../assets/images/icon03.png";
import icon04 from "./../assets/images/icon04.png";

import feature01 from "./../assets/images/feature01.png";
import feature02 from "./../assets/images/feature02.png";
import feature03 from "./../assets/images/feature03.png";
import feature04 from "./../assets/images/feature04.png";
import feature05 from "./../assets/images/feature05.png";
import feature06 from "./../assets/images/feature06.png";

import storage1 from "./../assets/images/storage1.png";
import storage2 from "./../assets/images/storage2.png";
import storage3 from "./../assets/images/storage3.png";
import storage4 from "./../assets/images/storage4.png";
import storage5 from "./../assets/images/storage5.png";
import storage6 from "./../assets/images/storage6.png";
import storage7 from "./../assets/images/storage7.png";
import storage8 from "./../assets/images/storage8.png";

import buyvideo from "./../assets/images/buy-video.png";
import ReactGA from "react-ga";
const Buy = () => {

  const ClickHandler=()=>{
   
    ReactGA.event({
      category:"link",
      action:'Click home page amazon buy button '
    })
  }
  return (
    <React.Fragment>
      <section>
        <div className="buy-section1">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="buy-head-img-div">
                <img src={midimage} className="buy-head-img" alt="image" />
              </div>
              <div className="Buy-btn-div">
                <a onClick={ClickHandler} href="https://www.amazon.in/BITTERSHOT-Unscented-Personal-Defence-Spray/dp/B09JVYL18J/ref=sr_1_5?crid=3HCNEVR5R0LG1&dchild=1&keywords=bittershot&qid=1635159453&qsid=257-3054091-6957760&sprefix=bittershot%2Caps%2C230&sr=8-5&sres=B09JVYL18J" target="_blank" className="buy-btn" >
                  buy now
                </a>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="buy-section1-info-div">
                <p className="buy-section1-head">
                  HI POWER BITTERSHOT <br />
                  PERSONAL DEFENCE SPRAY
                </p>
                <p className="buy-section1-info">
                  Net content : 60 g (65 ml) <br />
                  MRP : ₹ 375/- (inclusive of all taxes)
                </p>
                <p className="buy-section1-desc">
                  A Personal Defence Spray to deter an attacker intending
                  physical
                  <br /> harm. When cornered, aim at the attacker's face and
                  spray. It's a<br /> physical non-lethal spray that will
                  temporarily blind or cause severe
                  <br /> irritation to the attacker and trigger instant
                  distraction. Effective
                  <br />
                  against a human or a small animal attack.
                </p>
                <div className="avaliable-div">
                  <p className="buy-section1-desc">Also available at</p>
                  <div className="amazondiv">
                    <img src={amazon} className="amazonimg" />
                    <img src={flipkart} className="amazonimg" />
                    <img src={snapdeal} className="amazonimg" />
                    {/* <br />
                    <img src={shopclus} className="amazonimg" />
                    <img src={paytm} className="amazonimg" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="buy-section2bg">
          <div className="row">
            <div className="col-6 col-lg-4">
              <img src={Feature1} className="buy-feature" />
              <p className="buy-feature-text">REACH 6-8 FEET</p>
            </div>
            <div className="col-6 col-lg-4">
              <img src={Feature2} className="buy-feature" />
              <p className="buy-feature-text">STRIKING COLORS</p>
            </div>
            <div className="col-6 col-lg-4">
              <img src={Feature3} className="buy-feature" />
              <p className="buy-feature-text">
                ACCIDENTAL SPRAY
                <br />
                PROTECTION
              </p>
            </div>
            <div className="col-6 col-lg-4 feature-margin-top">
              <img src={Feature4} className="buy-feature" />
              <p className="buy-feature-text">EASY CARRY CLIP</p>
            </div>
            <div className="col-6 col-lg-4 feature-margin-top">
              <img src={Feature5} className="buy-feature" />
              <p className="buy-feature-text">HANDY SIZE</p>
            </div>
            <div className="col-6 col-lg-4 feature-margin-top">
              <img src={Feature6} className="buy-feature" />
              <p className="buy-feature-text">POWERFUL JET</p>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="video-bg">
          <img src={buyvideo} className="buyvideo-img" alt="buyvideo" />
        </div>
      </section> */}

      <section>
        <div className="buy-itwork">
          <p className="buy-subhead">HOW DOES IT WORK</p>
          <p className="buy-subhead-text">
            BITTERSHOT contains an extreme bitter chemical that is intolerable
            to humans and animals on contact. When BITTERSHOT is sprayed on the
            face of an attacker, it triggers an instant bitter sensation in the
            mouth and through the nasal cavity that deters the assailant.
          </p>
        </div>
      </section>

      <section>
        <div className="buy-ituse">
          <p className="buy-subhead">HOW TO USE</p>
          <div className="buy-usediv-row mobhide">
            <div className="usediv1">
              <div className="row">
                <div className="col-8 col-lg-8 no-padd-right">
                  <p className="icon01-text">
                    Hold the spray
                    <br /> can upright.
                  </p>
                </div>
                <div className="col-4 col-lg-4 no-padd">
                  <img src={icon01} className="icon01" alt="icon" />
                </div>
              </div>
            </div>
            <div className="usediv2">
              <div className="row">
                <div className="col-8 col-lg-8 no-padd-right">
                  <p className="icon01-text icon02-text">
                    Ensure that the
                    <br /> delivery nozzle faces
                    <br /> away from you.
                  </p>
                </div>
                <div className="col-4 col-lg-4 no-padd">
                  <img src={icon02} className="icon01 icon02" alt="icon" />
                </div>
              </div>
            </div>
            <div className="usediv3">
              <div className="row">
                <div className="col-8 col-lg-8 no-padd-right">
                  <p className="icon01-text">
                    Try and keep your mouth
                    <br /> covered with a handkerchief
                    <br /> or the other hand to prevent
                    <br /> any blowback.
                  </p>
                </div>
                <div className="col-4 col-lg-4 no-padd">
                  <img src={icon03} className="icon01" alt="icon" />
                </div>
              </div>
            </div>
            <div className="usediv4">
              <div className="row">
                <div className="col-8 col-lg-8 no-padd-right">
                  <p className="icon01-text">
                    Fully stretch your arm &<br />
                    hard press the trigger with
                    <br /> your index finger to
                    <br />
                    discharge the spray.
                  </p>
                </div>
                <div className="col-4 col-lg-4 no-padd">
                  <img src={icon04} className="icon01 icon04" alt="icon" />
                </div>
              </div>
            </div>
          </div>
          <div className="mob-buy-usediv-row deskhide">
            <div className="row">
              <div className="col-6">
                <div className="row">
                  <div className="col-4 no-padd">
                    <img src={icon01} className="icon01" alt="icon" />
                  </div>
                  <div className="col-8 no-padd">
                    <p className="icon01-text">
                      Hold the spray
                      <br /> can upright.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-4 no-padd">
                    <img src={icon02} className="icon01 icon02" alt="icon" />
                  </div>
                  <div className="col-8 no-padd">
                    <p className="icon01-text icon02-text">
                      Ensure that the
                      <br /> delivery nozzle faces
                      <br /> away from you.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-4 no-padd">
                    <img src={icon03} className="icon01" alt="icon" />
                  </div>
                  <div className="col-8 no-padd">
                    <p className="icon01-text">
                      Try and keep your mouth
                      <br /> covered with a handkerchief
                      <br /> or the other hand to prevent
                      <br /> any blowback.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-4 no-padd">
                    <img src={icon04} className="icon01 icon04" alt="icon" />
                  </div>
                  <div className="col-8 no-padd">
                    <p className="icon01-text">
                      Fully stretch your arm &<br />
                      hard press the trigger with
                      <br /> your index finger to
                      <br />
                      discharge the spray.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="buy-midboxbg">
          <div className="buy-midboxrow">
            <div className="row">
              <div className="col-4 col-lg-2">
                <div className="image-div">
                  <img src={feature01} className="feature01" />
                </div>
                <p className="feature01-text">
                  Useful in
                  <br />
                  Animal Attacks
                </p>
              </div>
              <div className="col-4 col-lg-2">
                <div className="image-div">
                  <img src={feature02} className="feature01" />
                </div>
                <p className="feature01-text">
                  Fast Release
                  <br />
                  Spray
                </p>
              </div>
              <div className="col-4 col-lg-2">
                <div className="image-div">
                  <img src={feature03} className="feature01" />
                </div>
                <p className="feature01-text">Non Toxic</p>
              </div>
              <div className="col-4 col-lg-2">
                <div className="image-div">
                  <img src={feature04} className="feature01" />
                </div>
                <p className="feature01-text">
                  No Permanent
                  <br />
                  Injuries
                </p>
              </div>
              <div className="col-4 col-lg-2">
                <div className="image-div">
                  <img src={feature05} className="feature01" />
                </div>
                <p className="feature01-text">Lightweight</p>
              </div>
              <div className="col-4 col-lg-2">
                <div className="image-div">
                  <img src={feature06} className="feature01" />
                </div>
                <p className="feature01-text">User Friendly</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="buy-itwork">
          <p className="buy-subhead">INGREDIENTS</p>
          <p className="buy-subhead-text">
            Bitterant 1% w/v - in an aqueous base{" "}
          </p>
        </div>
      </section>

      <section>
        <div className="storagediv">
          <p className="buy-subhead storage-head">STORAGE</p>
          <div className="storagerow mobhide">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="row">
                  <div className="col-4 col-lg-3">
                    <div className="storage-img-div">
                      <img src={storage1} className="storage1" />
                    </div>
                    <p className="storage-text">
                      Pressurized
                      <br />
                      container
                    </p>
                  </div>
                  <div className="col-4 col-lg-3">
                    <div className="storage-img-div">
                      <img src={storage2} className="storage1" />
                    </div>
                    <p className="storage-text">
                      Store in a cool,
                      <br />
                      dry place
                    </p>
                  </div>
                  <div className="col-4 col-lg-3">
                    <div className="storage-img-div">
                      <img src={storage3} className="storage1" />
                    </div>
                    <p className="storage-text">
                      away from
                      <br />
                      heat flame &<br />
                      strong light
                    </p>
                  </div>
                  <div className="col-4 col-lg-3">
                    <div className="storage-img-div">
                      <img src={storage4} className="storage1" />
                    </div>
                    <p className="storage-text">Non-refillable.</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="row">
                  <div className="col-4 col-lg-3">
                    <div className="storage-img-div">
                      <img src={storage5} className="storage1" />
                    </div>
                    <p className="storage-text">
                      Keep below
                      <br />
                      45°C<br />
                      temperature
                    </p>
                  </div>
                  <div className="col-4 col-lg-3">
                    <div className="storage-img-div">
                      <img src={storage6} className="storage1" />
                    </div>
                    <p className="storage-text">
                      Do not keep
                      <br />
                      in dashboard
                      <br />
                      compartment
                    </p>
                  </div>
                  <div className="col-4 col-lg-3">
                    <div className="storage-img-div">
                      <img src={storage7} className="storage1" />
                    </div>
                    <p className="storage-text">
                      Do not spray
                      <br />
                      on naked
                      <br />
                      flame
                    </p>
                  </div>
                  <div className="col-4 col-lg-3">
                    <div className="storage-img-div">
                      <img src={storage8} className="storage1" />
                    </div>
                    <p className="storage-text">
                      Do not
                      <br /> Puncture
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mob-storagerow deskhide">
            <div className="row">
              <div className="col-4 col-md-3">
                <div className="storage-img-div">
                  <img src={storage1} className="storage1" />
                </div>
                <p className="storage-text">
                  Pressurized
                  <br />
                  container
                </p>
              </div>
              <div className="col-4 col-md-3">
                <div className="storage-img-div">
                  <img src={storage2} className="storage1" />
                </div>
                <p className="storage-text">
                  Store in a cool,
                  <br />
                  dry place
                </p>
              </div>
              <div className="col-4 col-md-3">
                <div className="storage-img-div">
                  <img src={storage3} className="storage1" />
                </div>
                <p className="storage-text">
                  away from
                  <br />
                  heat flame &<br />
                  strong light
                </p>
              </div>
              <div className="col-4 col-md-3">
                <div className="storage-img-div">
                  <img src={storage4} className="storage1" />
                </div>
                <p className="storage-text">Non-refillable.</p>
              </div>
              <div className="col-4 col-md-3">
                <div className="storage-img-div">
                  <img src={storage5} className="storage1" />
                </div>
                <p className="storage-text">
                  Keep below
                  <br />
                  45°C<br />
                  temperature
                </p>
              </div>
              <div className="col-4 col-md-3">
                <div className="storage-img-div">
                  <img src={storage6} className="storage1" />
                </div>
                <p className="storage-text">
                  Do not keep
                  <br />
                  in dashboard
                  <br />
                  compartment
                </p>
              </div>
              <div className="offset-2 col-4 offset-md-0 col-md-3">
                <div className="storage-img-div">
                  <img src={storage7} className="storage1" />
                </div>
                <p className="storage-text">
                  Do not spray
                  <br />
                  on naked
                  <br />
                  flame
                </p>
              </div>
              <div className="col-4 col-md-3">
                <div className="storage-img-div">
                  <img src={storage8} className="storage1" />
                </div>
                <p className="storage-text">
                  Do not
                  <br /> Punchure
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Buy;
