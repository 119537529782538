import React from "react";

import can from "./../assets/images/usage-can.png";
import usagecan1 from "./../assets/images/usage-can1.png";
import icon1 from "./../assets/images/icon1.png";
import icon2 from "./../assets/images/icon2.png";
import icon3 from "./../assets/images/icon3.png";
import icon4 from "./../assets/images/icon4.png";

import newcan from '../assets/images/newcan.png';

const Usage = () => {
  return (
    <React.Fragment>
      <section>
        <div className="usage-section1bg">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="usage-section1-img">
                <img src={newcan} alt="can" />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <p className="usage-section1-head">
                <span>WHY</span> TO USE
              </p>
              <p className="usage-section1-text">
                With escalating human conflicts and societal issues --
                <br />
                looting, snatching, assault and individual human
                <br />
                attacks are on the rise. Human animal conflicts and
                <br />
                confrontations are quite prevalent. Such confrontations
                <br />
                can easily turn ugly, fatal or cause severe physical
                <br /> harm. BITTERSHOT can be an effective Personal
                <br /> Defense tool to escape from situations of physical
                <br /> threat. Late night travelers, individuals moving alone,
                <br />
                joggers, single residents, aged living alone, night
                <br /> guards are some of the vulnerable groups who can
                <br /> carry BITTERSHOT as a personal defense tool.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="usage-section2bg">
          <p className="user-guidance-head">
            USER <span>GUIDANCE</span>
          </p>
          <div className="guidance-row">
            <div className="row">
              <div className="col-12 col-md-6 mt-4">
                <div className="row">
                  <div className="col-9 col-lg-9">
                    <p className="icon-text">Hold the spray can upright.</p>
                  </div>
                  <div className="col-3 col-lg-3">
                    <img src={icon1} alt="icon" className="usage-icon" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 mt-4">
                <div className="row">
                  <div className="col-9 col-lg-9">
                    <p className="icon-text">
                      Ensure that the delivery nozzle
                      <br /> faces away from you.
                    </p>
                  </div>
                  <div className="col-3 col-lg-3">
                    <img src={icon2} alt="icon" className="usage-icon" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 mt-5">
                <div className="row">
                  <div className="col-9 col-lg-9">
                    <p className="icon-text">
                      Try and keep your mouth
                      <br /> covered with a handkerchief
                      <br /> or the other hand to prevent
                      <br /> any blowback.
                    </p>
                  </div>
                  <div className="col-3 col-lg-3">
                    <img src={icon3} alt="icon" className="usage-icon" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 mt-5">
                <div className="row">
                  <div className="col-9 col-lg-9">
                    <p className="icon-text">
                      Fully stretch your arm and hard
                      <br /> press the trigger with your index
                      <br /> finger to discharge the spray.
                    </p>
                  </div>
                  <div className="col-3 col-lg-3">
                    <img src={icon4} alt="icon" className="usage-icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="usage-smallcan-img">
            <img src={usagecan1} alt="can" />
          </div>
        </div>
      </section>

      <section>
        <div className="usage-midbannerbg">
          <div className="usage-midbanner-div">
            <div className="usage-blackbox">
              <p className="usage-blackbox-head">
                <span>WHEN</span> TO USE
              </p>
              <p className="usage-blackbox-text">
                BITTERSHOT is a powerful spray and one should use
                <br /> it responsibly, in situations that threaten physical
                <br /> harm. Self Defence is not a criminal offence, and this
                <br /> product can be safely used for protection.
              </p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Usage;
