import React from "react";

import upllogo from "./../assets/images/upl-logo.png";

const Footer = () => {
  return (
    <React.Fragment>
      <section className="no-padd">
        <div className="disclaimer-row">
          <div className="row">
            <div className="col-12 col-lg-6 caution-div">
              <p className="caution-head">CAUTION</p>
              <p className="caution-text">
                Avoid spraying indoors. Avoid spraying against the wind draft.
                Spray Range
                <br /> may reduce as the pressure drops. Highly bitter liquid
                containing chemical
                <br /> bitterant. Keep away from children.
                <br />
                <span>
                  USE ONLY FOR PERSONAL DEFENCE - DO NOT CARRY ON BOARD A FLIGHT
                </span>
              </p>
            </div>
            <div className="col-12 col-lg-6 disclaimer-div">
              <p className="caution-head">DISCLAIMER</p>
              <p className="caution-text">
                Since the use of the product is beyond the control of the
                manufacturer, the
                <br /> manufacturer is exempt from any liability or loss or
                damage to life or property
                <br /> of any nature whatsoever resulting from the use/ misuse
                or improper storage of
                <br /> this product. Complete responsibility rests with the
                owner/ carrier of this unit.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="no-padd">
        <footer>
          <img src={upllogo} alt="upl-logo" className="upl-logo" />
          <p className="footer-text">
            UPL is among the global leaders in agrochemicals and is engaged in
            manufacturing of a range of specialty chemical
            <br /> products. A public listed company on Indian stock exchanges,
            UPL has a successful track record of over 50 years
            <br /> in developing new research-based products to serve its
            customers. With worldwide business presence, manufacturing
            <br /> plants in multiple regions including India, Europe & South
            America, UPL is a complete Indian MNC. The specialty
            <br /> chemical business is focussed on science-based innovations
            for lubricants, flame retardants, water treatment, mining,
            <br /> textile, leather, and several industrial products. UPL is a
            leading producer of Denatonium Benzoate which is the world’s
            <br /> most bitter substance in taste. It finds application in
            various consumer and industrial products.
            <br />
            <br />
            <br />
            <span className="uptitle">UPL Ltd</span>
            <br />
            UPL House, 610 B/2, Bandra Village, Off Western Express Highway,
            Bandra (East), Mumbai 400051 India
            <br />
            <span className="copyright-text">
              © UPL Ltd. 2022. All rights reserved
            </span>
          </p>
        </footer>
      </section>
      {/* Modal  */}
      <div class="modal fade" id="buyModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              {/* <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> */}
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p class="buy-section1-desc">The product will be available soon on Amazon, Flipkart and Snapdeal, Thank you!</p>
            </div>
            {/* <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary">Save changes</button>
            </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
