import React, { useState } from "react";

import phoneicon from "./../assets/images/phone-icon.png";
import mailicon from "./../assets/images/mail-icon.png";
import fbicon from "./../assets/images/fb-icon.png";
import instaicon from "./../assets/images/insta-icon.png";
import ReactGA from "react-ga";
import axios from "axios";

const Enquiry = () => {
  const [form, setform] = useState({
    name: null,
    email: null,
    number: null,
    message: null,
  });
  const ContactNo=()=>{

    ReactGA.event({
      category:"Contack link",
      action:'Click Contact Number '
    })
  }

  const EmailId=()=>{

    ReactGA.event({
      category:"Email link",
      action:'Click Email Id '
    })
  }
  const Facebook=()=>{

    ReactGA.event({
      category:"FaceBook link",
      action:'Click FaceBook Link'
    })
  }
  const Instagram=()=>{

    ReactGA.event({
      category:"Instagram link",
      action:'Click Instagram Link'
    })
  }



  // console.log(form);
  const handleformsubmit = (e) => {
    e.preventDefault();
    
      ReactGA.event({
        category:"Enquiry Button",
        action:'Submit Get In Touch Form'
      })
    
    // console.log(form);
    if (form.name !== null && form.email !== null && form.number !== null) {
      console.log("proper form >> " + form.name);
      axios({
        url: "/phpmail/mail.php",
        method: "POST",
        data: {
          name: form.name,
          email: form.email,
          number: form.number,
          message: form.message,
        },
        headers: {
          "Content-Type": "application/json",
          // Authorization: "ApiKey",
        },
        // crossDomain: true,
      })
        .then(function (response) {
          console.log("sucesss >>" + response.data);
          if (response.data == "Mail sent successfully") {
            alert("Thank You!!");
            window.location.reload();
          } else {
            alert("Please try again");
            window.location.reload();
          }
        })
        .catch(function (error) {
          console.log("error >> ", error);
        })
        .finally(() => {});
      // window.location.reload();
    } else {
      alert("Please fill mandatory fields");
    }
  };
  return (
    <React.Fragment>
      <section>
        <div className="enquiry-bg">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6 enquiry-righ-brd">
              <p className="enquiry-head">
                CUSTOMER <span>CARE</span> &<br />
                TRADE <span>ENQUIRIES</span>
              </p>
              <div className="enquiry-info-div justify-content-center">
                <div className="row">
                  <div className="col-2 col-lg-2">
                  <a onClick={ContactNo}  href="tel:+912271528000"><img src={phoneicon} className="phoneicon" /></a>
                  </div>
                  <div className="col-10 col-lg-10">
                    <p className="phoneicon-text"><a onClick={ContactNo}  href="tel:+912271528000">+91-22-71528000</a></p>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-2 col-lg-2">
                  <a onClick={EmailId}  href="mailto:bittershot@upl-ltd.com"><img src={mailicon} className="phoneicon" /></a>
                  </div>
                  <div className="col-10 col-lg-10">
                    <p className="phoneicon-text"><a onClick={EmailId}  href="mailto:bittershot@upl-ltd.com">bittershot@upl-ltd.com</a></p>
                  </div>
                </div>
                <div className="followus-div">
                  <p className="follow-head">FOLLOW US</p>
                  <div className="fb-div">
                    <a onClick={Facebook}  href="https://www.facebook.com/Bittershot-106614011801273" target="_blank"><img src={fbicon} className="fb-icon" /></a>
                    <a onClick={Instagram}  href="https://www.instagram.com/bittershotpersonaldefencespray/" target="_blank"><img src={instaicon} className="fb-icon" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <p className="enquiry-head touch-head">
                GET IN
                <br />
                <span> TOUCH</span>
              </p>
              <div className="form-div">
                <p className="manatory">
                  <span>*</span>mandatory fields
                </p>
                <form action="#" className="enquiry-form">
                  <input
                    type="text"
                    className="form-control enquiry-input"
                    name="name"
                    placeholder="NAME*"
                    required
                    onChange={(e) => setform({ ...form, name: e.target.value })}
                  />
                  <div className="row mt-2">
                    <div className="col-7 col-lg-7 no-padd-right">
                      <input
                        type="text"
                        className="form-control enquiry-input"
                        name="email"
                        placeholder="EMAIL*"
                        required
                        onChange={(e) =>
                          setform({ ...form, email: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-5 col-lg-5">
                      <input
                        type="text"
                        className="form-control enquiry-input"
                        name="mobile"
                        placeholder="MOBILE*"
                        required
                        onChange={(e) =>
                          setform({ ...form, number: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <textarea
                    className="form-control textarea enquiry-input mt-2"
                    rows="4"
                    placeholder="MESSAGE"
                    name="submit"
                    onChange={(e) =>
                      setform({ ...form, message: e.target.value })
                    }
                  ></textarea>
                  <input
                    type="submit"
                    className="btn enquire-btn mt-2"
                    onClick={(e) => handleformsubmit(e)}
                    value="SUBMIT"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Enquiry;
